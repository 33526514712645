import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import AuthLayout from "../../layout/Layout";

const UserRoute = ({ isAuthenticated, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <AuthLayout {...props}>
          <Component {...props} />
        </AuthLayout>
      ) : (
        <Redirect to="/auth" />
      )
    }
  />
);

UserRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.authReducer.isLoggedIn
  };
}

export default connect(mapStateToProps)(UserRoute);
