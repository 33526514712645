import { GET_LABELS, CREATE_LABEL, DELETE_LABEL } from "../../actionTypes";

const initState = {
  labels: []
};
export default (state = initState, action) => {
  switch (action.type) {
    case GET_LABELS:
      state = {
        labels: action.payload
      };
      break;
    case CREATE_LABEL:
      state = {
        labels: [
          ...state.labels,
          {
            id: action.payload.label_id,
            name: action.payload.name
          }
        ]
      };
      break;
    case DELETE_LABEL:
      const labels = [...state.labels];
      const newLabels = labels.filter(label => label.id !== action.payload);
      state = {
        labels: newLabels
      };
      break;
    default:
      return state;
  }
  return state;
};
