import React, { useState } from "react";
import { connect } from "react-redux";
import { sendMessage } from "../../../redux/actions/BroadcastActions";
// import CardType from "./CardType";
import SearchBuilder from "../../search/SearchBuilder";

const ChatFooterActions = ({ cards, sendMessage, peopleCount, query }) => {
  const [textAreaRows, changeTextAreaRows] = useState(5);
  const [textArea, changeTextArea] = useState("");
  const [limit, onChangelimit] = useState(0);
  const [skip, onChangeSkip] = useState(0);

  const onChangeText = value => {
    changeTextArea(value);
    if (value.split("\n").length > 5) {
      changeTextAreaRows(value.split("\n").length);
    }
  };

  const boradcastData = {
    message: textArea,
    limit,
    skip,
    query: query
  };
  return (
    <div className="type_msg">
      <div className="input_msg_write">
        <h1>{peopleCount}</h1>
        <div className="container">
          <div className="row">
            <input
              className="col-md-3 input_area"
              type="number"
              name="skip"
              placeholder="Skip number"
              onChange={e => onChangeSkip(e.target.value)}
            />
            <input
              type="number"
              className="col-md-3 input_area"
              name="limit"
              placeholder="Broadcast limit"
              onChange={e => onChangelimit(e.target.value)}
            />
          </div>
          <SearchBuilder page={1} resetPage={null} hasNextPage={false} />
        </div>

        {/* <CardType /> */}

        <textarea
          name="title"
          value={textArea}
          rows={textAreaRows}
          onChange={e => onChangeText(e.target.value)}
          placeholder="enter json object"
        />
      </div>
      <button
        className="msg_send_btn"
        type="button"
        disabled={!textArea}
        onClick={() => sendMessage(boradcastData)}
      >
        <i className="fa fa-share"> </i>
      </button>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    cards: state.CardReducer.cards,
    query: state.QueryBuilderReducer.submitedQuery,
    peopleCount: state.PeopleReducer.people.count
  };
}
export default connect(
  mapStateToProps,
  {
    sendMessage
  }
)(ChatFooterActions);
