import { combineReducers } from "redux";

import authReducer from "./redux/reducers/AuthReducer";
import ModalReducer from "./redux/reducers/ModalReducer";
import PeopleReducer from "./redux/reducers/PeopleReducer";
import BroadcastReducer from "./redux/reducers/BroadcastReducer";
import LabelReducer from "./redux/reducers/LabelReducer";
import CardReducer from "./redux/reducers/CardReducer";
import SegmentReducer from "./redux/reducers/SegmentReducer";
import QueryBuilderReducer from "./redux/reducers/QueryBuilderReducer";
import NotificationReducer from "./redux/reducers/NotificationReducer";
import PausedListReducer from "./redux/reducers/PausedListReducer";

const rootReducer = {
  authReducer,
  ModalReducer,
  PeopleReducer,
  BroadcastReducer,
  LabelReducer,
  CardReducer,
  SegmentReducer,
  QueryBuilderReducer,
  NotificationReducer,
  PausedListReducer
};

export default combineReducers(rootReducer);
