import React from "react";
import PropTypes from "prop-types";

const InlineError = ({ error }) => {
  return (
    <small id="emailHelp" className="form-text has-error">
      Oh noes! {error}.
    </small>
  );
};

InlineError.propTypes = {
  error: PropTypes.string.isRequired
};

export default InlineError;
