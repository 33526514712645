import React, { useState } from "react";
import { connect } from "react-redux";
import { createNewSegment } from "../../../redux/actions/SegmentAction";
import Segments from "./segments/Segments";
const SegmentsList = ({ submitedQuery, createNewSegment }) => {
  const [segment, changeSegment] = useState(null);
  const [editableSegment, changeEditableSegment] = useState(null);

  const createSegmentRequestBody = (segment, submitedQuery) => {
    return {
      query: `
        mutation{
            createSegment(
              name:"${segment.name}",
              query:${submitedQuery}
              ){
              _id
              name
            }
           }
        `
    };
  };

  const createSegment = () => {
    let newSegmente = {
      _id: Date.now(),
      name: `New Segment`
    };
    changeSegment(newSegmente);
    changeEditableSegment(newSegmente._id);
  };

  const handleSegmentNameChange = event => {
    changeSegment({
      name: event.target.value
    });
  };

  const saveSegment = async () => {
    changeEditableSegment(null);
    changeSegment(null);
    const createSegmentRequest = createSegmentRequestBody(
      segment,
      submitedQuery
    );
    try {
      createNewSegment(createSegmentRequest);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <Segments />

          {segment && (
            <React.Fragment>
              <input
                type="text"
                autoFocus
                value={segment.name}
                onChange={event => handleSegmentNameChange(event)}
              />
              <button
                className="btn btn-primary"
                title="select users first"
                onClick={() => saveSegment()}
              >
                save segment
              </button>
            </React.Fragment>
          )}
          <button
            className="btn add-btn custom-btn-main"
            title="select users first"
            disabled={editableSegment}
            onClick={() => createSegment()}
          >
            +
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = state => {
  return {
    submitedQuery: state.QueryBuilderReducer.submitedQuery
  };
};
export default connect(
  mapStateToProps,
  { createNewSegment }
)(SegmentsList);
