const id = Date.now();
export default {
  search: [
    {
      id: id,
      group: null,
      column: null,
      operation: null,
      value: [],
      sub_operation: null
    }
  ],
  querySelection: [
    {
      id: id,
      group: [
        { value: "user_data", label: "User Data" },
        { value: "attributes", label: "Attributes" },
        { value: "segment", label: "segment" }
      ],
      columns: [],
      operations: [],
      values: [{ value: "not_set", label: "Not Set" }],
      sub_operation: [
        { value: "or", label: "or" },
        { value: "and", label: "and" }
      ]
    }
  ],
  isLoading: true
};
