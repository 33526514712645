import decode from "jwt-decode";

export default () => {
  let userObject = JSON.parse(localStorage.getItem("userData"));
  const tokenDecoded = decode(userObject.token);
  let date = new Date();
  date = Math.round(date.getTime() / 1000);
  if (tokenDecoded.exp < date) return true;
  else return false;
};
