import {
  FETCH_QUERY_BUILDER_USER_DATA,
  FETCH_QUERY_BUILDER_ATTRIBUTES_DATA,
  FETCH_QUERY_BUILDER_SEGMENTS_DATA,
  SUBMITED_QUERY
} from "../../actionTypes";

const initState = {
  userData: [],
  attributeData: [],
  segmentData: [],
  submitedQuery: null
};
export default (state = initState, action) => {
  switch (action.type) {
    case FETCH_QUERY_BUILDER_USER_DATA:
      state = {
        ...state,
        userData: action.payload
      };
      break;
    case FETCH_QUERY_BUILDER_ATTRIBUTES_DATA:
      state = {
        ...state,
        attributeData: action.payload
      };
      break;
    case FETCH_QUERY_BUILDER_SEGMENTS_DATA:
      state = {
        ...state,
        segmentData: action.payload
      };
      break;
    case SUBMITED_QUERY:
      state = {
        ...state,
        submitedQuery: action.payload
      };
      break;
    default:
      return state;
  }
  return state;
};
