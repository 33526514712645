import React from "react";
import People from "./People";

const PeopleTable = ({
  listenScrollEvent,
  people,
  meta,
  selectedPersons,
  showedPerson,
  handleSelectedPerson,
  handleToggleSelectPeople
}) => {
  return (
    <React.Fragment>
      <h4>People table</h4>
      <h5>
        <i className="fa fa-users" /> {meta.count}
      </h5>
      {people && people.length > 0 ? (
        <div className="table-responsive" onScroll={e => listenScrollEvent(e)}>
          <table className="table table-hover">
            <thead className="table-active">
              <tr>
                <th scope="col">
                <input type="checkbox"  data-toggle="toggle" data-size="xs" name="selectedUsers"
                onChange={() => handleToggleSelectPeople() }/>
                </th>
                <th scope="col">User</th>
                <th scope="col">Local</th>
                <th scope="col">Gender</th>
                <th scope="col">Sessions</th>
                <th scope="col">
                  UnSubscribed
                </th>
                <th scope="col" className="pauser-th">Paused</th>
                <th scope="col" className="text-center">
                  Contact
                </th>
                <th scope="col" className="text-center">
                  Bot Name
                </th>
              </tr>
            </thead>
            <tbody>
              <People
                people={people}
                selectedPersons={selectedPersons}
                showedPerson={personId => showedPerson(personId)}
                selectPerson={personId => handleSelectedPerson(personId)}
              />
            </tbody>
          </table>
        </div>
      ) : (
        <h1 className="text-center">No users</h1>
      )}
    </React.Fragment>
  );
};

export default PeopleTable;
