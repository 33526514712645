const fetchData = (data, needAuth = false) => {
  const URL = getURL();
  const headers = requestHeaders(needAuth);
  return fetch(URL, {
    method: "POST",
    body: JSON.stringify(data),
    headers
  });
};

const getURL = () => {
  if (process.env.REACT_APP_ENV === "local") {
    return `${process.env.REACT_APP_LOCAL_DOMAIN_API}/graphql`;
  } else if (process.env.REACT_APP_ENV === "dev") {
    return `${process.env.REACT_APP_DEVELOPMENT_DOMAIN_API}/graphql`;
  }
  return `${process.env.REACT_APP_PRODUCTION_DOMAIN_API}/graphql`;
};

const requestHeaders = (needAuth = false) => {
  let headers = {
    "Content-Type": "application/json"
  };
  if (needAuth) {
    if (localStorage.userData) {
      const user = JSON.parse(localStorage.getItem("userData"));
      if (user.token) {
        headers["Authorization"] = `Bearer ${user.token}`;
      }
    }
  }
  return headers;
};

export default {
  user: {
    login: data => fetchData(data, false)
  },
  people: {
    fetchPeople: data => fetchData(data, true),
    changePause: (facebookId,currentPausedValue) => fetchData({
      query: `
          mutation{
              PauseUser(fb_id:"${facebookId}",paused:${!currentPausedValue})
          }
      `
    }, true)

  },
  broadcast: {
    sendBroadcast: data => fetchData(data, true),
    getPersonChat: data => fetchData(data, true)
  },
  labels: {
    createNewLabel: data => fetchData(data, true),
    fetchLabels: data => fetchData(data, true),
    associateToLabel: data => fetchData(data, true),
    deleteLabel: data => fetchData(data, true)
  },
  segments: {
    fetchSegments: data => fetchData(data, true),
    createNewSegment: data => fetchData(data, true),
    deleteSegment: data => fetchData(data, true)
  },
  queryBuilder: {
    fetchQueryData: data => fetchData(data, true),
    submitQuery: data => fetchData(data, true)
  },
  pausedList:{
    index:  data => fetchData(data, true),
  }
};
