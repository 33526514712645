import React from "react";
import AuthDetailes from "../components/auth/AuthDetailes";
import LoginForm from "../components/auth/login/LoginForm";
import PropTypes from "prop-types";

const Auth = () => {
  return (
    <div className="login-page  ">
      <div className="container">
        <div className="row">
          <div className="col-md-6 fullvh d-flex align-items-center">
            <LoginForm />
          </div>
          <div className="col-md-6 sally-background fullvh d-flex align-items-center flex-column">
            <AuthDetailes />
          </div>
        </div>
      </div>
    </div>
  );
};
Auth.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default Auth;
