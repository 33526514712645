import React from 'react';
import AppModal from '../AppModal'

const PersonModal = ({user}) => {
    return (
        <div className="container">
            <AppModal name="personModal row">
            <div className="card col-md-6">
                <img className="card-img-top" src={user.fb_profile_pic} alt="User pp"/>
                <div className="card-body">
                    <h5 className="card-title">{user.first_name} {user.last_name}</h5>
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        id
                        <span> {user._id}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        name
                        <span >{user.first_name} {user.last_name}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        facebook id
                        <span >{user.fb_id}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        locale
                        <span >{user.locale}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        number of sessions
                        <span >{user.no_of_sessions}</span>
                    </li>

                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        last session id
                        <span >{user.last_session_id}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        gender
                        <span >{user.gender}</span>
                    </li>
                    {user.last_message && (
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            last message
                            <span>{user.last_message}</span>
                        </li>
                    )}

                    {user.last_message_time && (
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            last message time
                            <span >{user.last_message_time}</span>
                        </li>
                    )}

                    {user.conversation_id && (
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            conversation id
                            <span>{user.conversation_id}</span>
                        </li>
                    )}
                </ul>
            </div>
            <div className="card col-md-6 padding-top-65">
                <ul className="list-group list-group-flush">
                <h4>Competition Data</h4>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                    Name
                    <span >{user.name}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                    Email
                    <span >{user.email}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                    Phone
                    <span >{user.phone}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                    Location
                    <span >{user.location}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                    Available Tries
                    <span >{user.availableTries}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                    Invitations
                    <span ></span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                    Subscribe
                    <span >{user.subscribe}</span>
                </li>
                </ul>
                </div>

            </AppModal>
        </div>
    );
};

export default PersonModal;
