import React, { Component } from "react";
import SearchRow from "./SearchRow";
import {
  getQueryUserData,
  getQueryAttributesData,
  getQuerySegmentData,
  submitQueryData,
  updateQueryBuilder,
  submitQueryDataWithAppend
} from "../../redux/actions/QueryBuilderActions";
import { connect } from "react-redux";
import _ from "lodash";
import { jsUcfirst } from "../../utils/stringHelpers";
import { _getObjectKeyValues } from "../../utils/queryBuilderHelper";
import InitialState from "./initialState";
class SearchBuilder extends Component {
  state = InitialState;
  componentDidMount() {
    this.handleSendQueryBuilder();
    this.setState({
      isLoading: false
    });
  }

  addRow = id => {
    this.editRow(id);
    this.addNewRow();
  };

  onChange = async (id, name, value) => {
    const rows = [...this.state.search];
    let index = this.state.search.findIndex(row => row.id === id);
    rows[index][name] = value;

    if (name === "group") {
      let requestBody = null;
      switch (value.value) {
        case "user_data":
          if (this.props.queryData.userData.length < 1) {
            requestBody = this.fetchQueryDataForSearch("user_data");
            await this.props.getQueryUserData(requestBody);
          }
          break;
        case "attributes":
          if (this.props.queryData.attributeData.length < 1) {
            requestBody = this.fetchQueryDataForSearch("attributes");
            await this.props.getQueryAttributesData(requestBody);
          }
          break;
        case "segment":
          if (this.props.queryData.segmentData.length < 1) {
            requestBody = await this.fetchQueryDataForSearch("segment");
            await this.props.getQuerySegmentData(requestBody);
          }
          break;

        default:
          break;
      }
    }
    this.updateQuerySelection(id, name, value);
    this.setState({
      search: rows
    });
    this.handleSendQueryBuilder();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.handleSendQueryBuilder(true);
    }
  }

  handleSendQueryBuilder = _.debounce((afterScroll = false) => {
    const searchData = this.state.search.map(searchRow => {
      let searchRowTransformed = _getObjectKeyValues(searchRow);
      return {
        ...searchRowTransformed,
        id: searchRow.id.toString()
      };
    });
    let queryData = JSON.stringify(searchData).replace(/"(\w+)"\s*:/g, "$1:");
    const requestBody = {
      query: `
            query{
              submitQueryBuilder(page:${this.props.page},query:${queryData}){
                people{
                  _id
                  fb_id
                  first_name
                  last_name
                  fb_profile_pic
                  locale
                  gender
                  no_of_sessions
                  paused
                  last_session_id
                  phone
                  name
                  location
                  availableTries
                  email
                  bot_name
                  unsubscribe
               }
               count
               hasNextPage
               limit
               pageNumbers
               currentPage
              }
            }
            `
    };

    this.props.updateQueryBuilder(queryData);
    if (afterScroll && this.props.hasNextPage) {
      return this.props.submitQueryDataWithAppend(requestBody);
    }
    return this.props.submitQueryData(requestBody);
  }, 1000);

  editRow = id => {
    const rows = [...this.state.search];
    let index = this.state.search.findIndex(row => row.id === id);
    rows[index].sub_operation = { label: "or", value: "or" };

    this.setState({
      search: rows
    });
  };

  addNewRow = (name, value) => {
    const id = Date.now();
    this.setState({
      search: [
        ...this.state.search,
        {
          id: id,
          group: null,
          column: null,
          operation: null,
          value: [],
          sub_operation: null
        }
      ],
      querySelection: [
        ...this.state.querySelection,
        {
          id: id,
          group: [
            { value: "user_data", label: "User Data" },
            { value: "attributes", label: "Attributes" },
            { value: "segment", label: "segment" }
          ],
          columns: [],
          operations: [],
          values: [{ value: "not_set", label: "Not Set" }],
          sub_operation: [
            { value: "or", label: "or" },
            { value: "and", label: "and" }
          ]
        }
      ]
    });
  };
  deleteRow = id => {
    const search = [...this.state.search];
    const querySelection = [...this.state.querySelection];

    const searchIndex = this.state.search.findIndex(row => row.id === id);
    const querySelectionIndex = this.state.querySelection.findIndex(
      query => query.id === id
    );
    if (searchIndex !== -1 && querySelectionIndex !== -1) {
      querySelection.splice(querySelectionIndex, 1);
      search.splice(searchIndex, 1);
    }

    if (searchIndex - 1 !== -1) {
      search[searchIndex - 1].sub_operation = null;
    }

    this.setState({
      ...this.state,
      search
    });
    this.handleSendQueryBuilder();
  };

  resetRow = id => {
    const search = [...this.state.search];
    let searchIndex = search.findIndex(row => row.id === id);
    if (searchIndex !== -1) {
      search[searchIndex].group = null;
      search[searchIndex].column = null;
      search[searchIndex].operation = null;
      search[searchIndex].value = [];
      search[searchIndex].sub_operation = null;

      this.setState({
        search
      });
      this.props.resetPage();
      this.handleSendQueryBuilder();
    }
  };

  fetchQueryDataForSearch = queryType => {
    if (queryType === "segment") {
      return {
        query: `
              mutation{
                createQuery(type:"${queryType}"){
                operations
                values
              }
            }
              `
      };
    }
    return {
      query: `
            mutation{
              createQuery(type:"${queryType}"){
              key
              type
              operations
              values
            }
          }
            `
    };
  };

  updateQuerySelection = (queryIndex, name, value = null) => {
    const querySelection = [...this.state.querySelection];
    const querySelectionIndex = querySelection.findIndex(
      query => query.id === queryIndex
    );

    const search = [...this.state.search];
    const searchIndex = search.findIndex(search => search.id === queryIndex);

    if (querySelectionIndex !== -1) {
      let loadedData = null;
      switch (name) {
        case "group":
          loadedData = this.selectedData(value.value);
          if (searchIndex !== -1) {
            console.log(search[searchIndex]);
            search[searchIndex].column = null;
            search[searchIndex].operation = null;
            search[searchIndex].value = [];
            // search[searchIndex].sub_operation = null;
            this.setState({
              search
            });
          }

          if (value.value !== "segment") {
            querySelection[querySelectionIndex] = {
              ...querySelection[querySelectionIndex],
              operations: [],
              values: [{ value: "not_set", label: "Not Set" }],
              columns: loadedData.map(dataRow => {
                return {
                  value: dataRow.key,
                  label: jsUcfirst(dataRow.key)
                };
              })
            };
          } else {
            const values = [];
            this.props.queryData.segmentData.map(segmentsData => {
              return segmentsData.values.map(value => {
                values.push({
                  value: value,
                  label: jsUcfirst(value)
                });
              });
            });
            querySelection[querySelectionIndex] = {
              ...querySelection[querySelectionIndex],
              operations: [
                { value: "is", label: "Is " },
                { value: "is not", label: "Is Not" }
              ],
              values,
              columns: []
            };
          }

          this.setState({
            ...this.state,
            querySelection
          });
          break;

        case "column":
          loadedData = this.selectedData(search[searchIndex].group.value);
          const SelectedColumnObject = loadedData.find(
            data => data.key === value.value
          );
          const columnOperations = SelectedColumnObject.operations.map(
            operation => {
              return {
                value: operation,
                label: jsUcfirst(operation)
              };
            }
          );

          querySelection[querySelectionIndex] = {
            ...querySelection[querySelectionIndex],
            operations: columnOperations
          };

          let columnValues = null;
          if (SelectedColumnObject.values) {
            columnValues = SelectedColumnObject.values.map(value => {
              return {
                value: value,
                label: jsUcfirst(value)
              };
            });
            querySelection[querySelectionIndex].values = [
              ...columnValues,
              ...querySelection[querySelectionIndex].values
            ];
          }
          this.setState({
            ...this.state,
            querySelection: querySelection
          });
          break;

        default:
          break;
      }
    }
  };

  selectedData = type => {
    let loadedData = null;
    switch (type) {
      case "user_data":
        loadedData = this.props.queryData.userData;
        break;
      case "attributes":
        loadedData = this.props.queryData.attributeData;
        break;
      case "segments":
        loadedData = this.props.queryData.segmentData;
        break;

      default:
        break;
    }
    return loadedData;
  };
  render() {
    if (this.state.isLoading) {
      return <h1>loading</h1>;
    }
    let rowCount = this.state.search.length;

    return (
      <div>
        {this.state.search.map((row, i) => (
          <SearchRow
            key={i}
            row={row}
            className="col-md-3"
            onChange={this.onChange}
            addNewRow={this.addRow}
            rowCount={rowCount}
            querySelection={this.state.querySelection[i]}
          >
            {rowCount < 2 && (
              <button
                className="btn custom-btn-main "
                onClick={() => this.resetRow(row.id)}
              >
                <i className="fas fa-redo-alt" />
              </button>
            )}
            {rowCount > 1 && (
              <button
                className="btn custom-btn-main"
                onClick={() => this.deleteRow(row.id)}
              >
                <i className="far fa-trash-alt" />
              </button>
            )}
          </SearchRow>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: {
      userData: state.QueryBuilderReducer.userData,
      attributeData: state.QueryBuilderReducer.attributeData,
      segmentData: state.QueryBuilderReducer.segmentData
    }
  };
};
export default connect(
  mapStateToProps,
  {
    getQueryUserData,
    getQueryAttributesData,
    getQuerySegmentData,
    submitQueryData,
    submitQueryDataWithAppend,
    updateQueryBuilder
  }
)(SearchBuilder);
