import React from "react";
import PropTypes from "prop-types";
import USLogo from "../../assets/images/us.svg";
import SALogo from "../../assets/images/sa.svg";
import {changePause} from '../../redux/actions/PeopleActions'
import { connect } from "react-redux";
import Switch from "react-switch";

const Person = ({ person, showedPerson, selectPerson, wasSelected, changePause }) => {
  let icon = null;

  if (person.locale === "en") {
    icon = <img src={USLogo} alt="country" />;
  } else {
    icon = <img src={SALogo} alt="country" />;
  }
  return (
    <tr>
      <td>
        <input
          type="checkbox"
          name="selectedUsers"
          checked={wasSelected}
          onChange={() => selectPerson(person._id)}
        />
      </td>
      <td onClick={() => showedPerson(person._id)}>
        <div className="table-user-section">
          {person.fb_profile_pic && (
            <img
              src={person.fb_profile_pic}
              className="user-image"
              alt="user pp"
            />
          )}

          <div className="user-information">
            <h4>
              {" "}
              {person.first_name} {person.last_name}
            </h4>
            <span className="info">{person.fb_id}</span>
          </div>
        </div>
      </td>
      <td>{icon}</td>
      <td>{person.gender}</td>
      <td>
        <div className="user-information">
          <span className="info">{person.no_of_sessions}</span>
        </div>
      </td>
      <td>
        <div className="user-information">
          <span className="info">{person.unsubscribe}</span>
        </div>
      </td>
      <td>
        <div className="user-information">
          <Switch onChange={() => changePause(person.fb_id,person.paused)} checked={person.paused} />

        </div>
      </td>
      <td className="text-center">
        <span>
          <svg
            width="21px"
            height="21px"
            viewBox="0 0 17 17"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Symbols"
              stroke="none"
              strokeWidth="1"
              fill="#c1c1c1"
              fillRule="evenodd"
            >
              <g id="user-yes" transform="translate(-1065.000000, -24.000000)">
                <g id="1">
                  <path
                    className="mes-icon"
                    d="M1071.03475,39.4136895 L1068.04478,41 L1068.04478,37.9144213 C1066.18355,36.4698434 1065,34.3004754 1065,31.875 C1065,27.5257576 1068.80558,24 1073.5,24 C1078.19442,24 1082,27.5257576 1082,31.875 C1082,36.2242424 1078.19442,39.75 1073.5,39.75 C1072.64268,39.75 1071.81501,39.6324101 1071.03475,39.4136895 Z M1068.04602,34.53479 L1072.16915,32.2834473 L1074.45273,34.5021973 L1079.0199,29.7209473 L1074.83333,32.00354 L1072.6449,29.72229 L1068.04602,34.53479 Z"
                    id="Combined-Shape"
                  />
                </g>
              </g>
            </g>
          </svg>
        </span>
      </td>
      <td>{person.bot_name}</td>

    </tr>
  );
};

Person.propTypes = {
  Person: PropTypes.shape({
    _id: PropTypes.string,
    fb_profile_pic: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    fb_id: PropTypes.string,
    locale: PropTypes.string,
    gender: PropTypes.string,
    no_of_sessions: PropTypes.number,
    last_session_id: PropTypes.string,
    name:PropTypes.string,
    phone:PropTypes.string,
    email:PropTypes.string,
    location:PropTypes.string,
    availableTries:PropTypes.number,
    bot_name:PropTypes.string
  }),
  wasSelected: PropTypes.bool.isRequired,
  showedPerson: PropTypes.func.isRequired,
  selectPerson: PropTypes.func.isRequired
};

export default connect(null,{changePause})(Person);
