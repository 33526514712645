export default class Validation {
  constructor(formValidation) {
    if (!(formValidation instanceof Object)) {
      throw new Error("not object");
    }
    this.form = formValidation;
    return this.validateFunctions();
  }

  validateFunctions() {
    let errorBag = {};
    Object.keys(this.form).map(key => {
      return this.form[key].rules.map(rule => {
        if (typeof this[rule] === "function") {
          let error = null;
          if (
            (error = this[rule](key, this.form[key].obj.current.value)) !== true
          ) {
            if (typeof errorBag[key] === "undefined") {
              errorBag[key] = [];
            }
            errorBag[key].push(error);
          }
        } else {
          throw new Error(`${rule} not a function in class Validation`);
        }
      });
    });
    return errorBag;
  }

  required(key, value) {
    if (value.trim().length < 1) {
      return `${key} is required`;
    }
    return true;
  }

  email(key, value) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(value).toLowerCase())) {
      return `${key} is not valid`;
    }
    return true;
  }
  password(key, value) {
    if (value.trim().length < 8) {
      return `${key} is small`;
    }
    return true;
  }
}
