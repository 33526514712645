import {
  FETCH_SEGMENTS,
  CREATE_SEGMENT,
  DELETE_SEGMENT
} from "../../actionTypes";

const initState = {
  segments: [],
  error: null
};
export default (state = initState, action) => {
  switch (action.type) {
    case FETCH_SEGMENTS:
      state = {
        segments: action.payload,
        error: null
      };
      break;
    case CREATE_SEGMENT:
      state = {
        segments: [
          ...state.segments,
          {
            _id: action.payload._id,
            name: action.payload.name
          }
        ],
        error: null
      };
      break;
    case DELETE_SEGMENT:
      const segments = [...state.segments];
      const newSegment = segments.filter(
        segment => segment._id !== action.payload
      );
      state = {
        segments: newSegment,
        error: null
      };
      break;
    default:
      return state;
  }
  return state;
};
