import {
  SET_BROADCAST_TO_ALL,
  SET_BROADCAST_TO_PERSON,
  SET_BROADCAST_TO_SEGMENT
} from "../../actionTypes";
import api from "../../api";

export const personChatFetched = (chat, activePerson) => {
  return {
    type: SET_BROADCAST_TO_PERSON,
    payload: {
      chat,
      activePerson
    }
  };
};
export const labelChatFetched = (chat, activeLabel) => {
  return {
    type: SET_BROADCAST_TO_SEGMENT,
    payload: {
      chat,
      activeLabel
    }
  };
};

export const allChatFetched = () => {
  return {
    type: SET_BROADCAST_TO_ALL,
    payload: null
  };
};

export const broadCastToAll = () => dispatch => {
  dispatch(allChatFetched());
};

export const broadCastToPerson = person => dispatch => {
  const requestBody = {
    query: `
                query{
                    personMessages(personId:"${person._id}"){
                        text
                        createdAt
                        watsonData{
                            text
                        }
                      }
                }
            `
  };
  return api.broadcast
    .getPersonChat(requestBody)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error("Failed!");
      }
      return res.json();
    })
    .then(resData => {
      dispatch(personChatFetched(resData.data.personMessages, person));
    });
};
export const broadCastToLabel = label => dispatch => {
  dispatch(labelChatFetched([], label));
};

export const sendMessage = data => dispatch => {
  var confirm = window.confirm(`send proadcast for ${data.limit} user after skipping ${data.skip} users`);
    if (confirm == true) {
  // console.log(JSON.parse(data.cards));
  const message = JSON.stringify(data.message.replace(/"/g, "'"));
  const requestBody = {
    query: `
            mutation{
                broadcast(
                    message:${message},
                    limit:${data.limit},
                    skip:${data.skip}
                    query:${data.query}
                ){
                    broadcast
                }
           }
        `
  };
  console.log(requestBody);

  return api.broadcast
    .sendBroadcast(requestBody)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error("Failed!");
      }
      return res.json();
    })
    .then(resData => {
      console.log(resData);
    });
  }
};
