import React, { useState } from "react";
import MainHeader from "../components/MainHeader";
import SideNav from "../components/SideNav";
import { connect } from "react-redux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group"; // ES6

const Layout = props => {
  const [smallNav, toggleSmallNav] = useState(false);
  return (
    <React.Fragment>
      <main>
        {props.errors.length > 0 && (
          <ul className="notification">
            <ReactCSSTransitionGroup
              transitionName="fade"
              transitionAppear={true}
              transitionAppearTimeout={400}
              transitionEnterTimeout={400}
              transitionLeaveTimeout={400}
              transitionEnter={true}
              transitionLeave={true}
            >
              {props.errors.map((error, index) => {
                return (
                  <li key={index} className="notification-item">
                    {error}
                  </li>
                );
              })}
            </ReactCSSTransitionGroup>
          </ul>
        )}

        <div className="d-flex w-100 mainDiv">
          <div className={smallNav ? "side-nav smallNav" : "side-nav"}>
            <SideNav
              {...props}
              smallNav={smallNav}
              toggleNav={() => toggleSmallNav(prevSmallNav => !prevSmallNav)}
            />
          </div>
          <div className={smallNav ? "content smallNav" : "content"}>
            <MainHeader />
            {props.children}
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};
const mapStateToProps = state => {
  return {
    errors: state.NotificationReducer.errors
  };
};
export default connect(
  mapStateToProps,
  null
)(Layout);
