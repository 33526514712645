import React, { useState, useEffect } from "react";
import Segment from "./segment/Segment";
import DeleteModal from "../../../modals/DeleteModal";
import { setActiveModal } from "../../../../redux/actions/ModalActions";
import {
  fetchSegments,
  deleteSegment
} from "../../../../redux/actions/SegmentAction";
import { connect } from "react-redux";

const Segments = ({
  fetchSegments,
  fetchedSegments,
  setActiveModal,
  deleteSegment
}) => {
  const [showDeleteModalData, changeShowDeleteModalData] = useState(null);

  useEffect(() => {
    fetchMyAPI();
  }, []);

  async function fetchMyAPI() {
    await fetchSegments(segmentsRequest());
  }

  const segmentsRequest = () => {
    return {
      query: `
        query{
            listSegment{
             _id
             name
            }
          }
              `
    };
  };

  const handleShowDeleteSegment = id => {
    const segments = [...fetchedSegments];
    const index = segments.findIndex(segment => segment._id === id);
    if (index !== -1) {
      setActiveModal("deleteModal");
      changeShowDeleteModalData({
        data: segments[index],
        type: "segment"
      });
    }
  };

  const confirmDelete = () => {
    if (showDeleteModalData) {
      if (showDeleteModalData.type === "segment") {
        const requestBody = {
          query: `
                mutation{
                  deleteSegment(id:"${showDeleteModalData.data._id}")
                }
            `
        };
        return deleteSegment(requestBody, showDeleteModalData._id).then(res => {
          changeShowDeleteModalData(null);
          fetchMyAPI();
        });
      }
    }
  };

  let deleteModal = null;
  if (showDeleteModalData) {
    deleteModal = (
      <DeleteModal
        type={showDeleteModalData.type}
        data={showDeleteModalData.data.name}
        confirmDelele={() => confirmDelete()}
      />
    );
  }

  return (
    <React.Fragment>
      {fetchedSegments.map(segment => (
        <Segment
          key={segment._id}
          segment={segment}
          deleteSegment={() => handleShowDeleteSegment(segment._id)}
        />
      ))}
      {deleteModal}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    fetchedSegments: state.SegmentReducer.segments
  };
};

export default connect(
  mapStateToProps,
  { setActiveModal, deleteSegment, fetchSegments }
)(Segments);
