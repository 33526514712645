import React from "react";
import Logo from "../../../src/assets/images/sally-logo.png";
const AuthDetailes = () => {
  return (
    <React.Fragment>
      <img src={Logo} alt="sally logo" className="sallylogo" />
      <p className="sallydesc">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque sed
        beatae eos cupiditate veritatis quos.
      </p>
    </React.Fragment>
  );
};

export default AuthDetailes;
