import React, { useState, useEffect } from "react";
import Label from "./label/Label";
import DeleteModal from "../../../modals/DeleteModal";
import { setActiveModal } from "../../../../redux/actions/ModalActions";
import { getLabels, deleteLabel } from "../../../../redux/actions/LabelActions";
import { connect } from "react-redux";

const Labels = ({ setActiveModal, getLabels, fetchedLabels, deleteLabel }) => {
  const [showDeleteModalData, changeShowDeleteModalData] = useState(null);

  useEffect(() => {
    fetchMyAPI();
  }, []);

  async function fetchMyAPI() {
    await getLabels(labelsRequest());
  }
  const labelsRequest = () => {
    return {
      query: `
            query{
                listLabel{
                    id
                    name
                }
            }
        `
    };
  };

  const handleShowDeleteLabel = id => {
    const labels = [...fetchedLabels];
    const index = labels.findIndex(label => label.id === id);
    if (index !== -1) {
      setActiveModal("deleteModal");
      changeShowDeleteModalData({
        data: labels[index],
        type: "label"
      });
    }
  };

  const confirmDelete = () => {
    if (showDeleteModalData) {
      if (showDeleteModalData.type === "label") {
        const requestBody = {
          query: `
          mutation{
            deleteLabel(labelId:"${showDeleteModalData.data.id}"){
                success
            }
        }
            `
        };
        return deleteLabel(requestBody, showDeleteModalData.data.id).then(
          res => {
            changeShowDeleteModalData(null);
            fetchMyAPI();
          }
        );
      }
    }
  };

  let deleteModal = null;
  if (showDeleteModalData) {
    deleteModal = (
      <DeleteModal
        type={showDeleteModalData.type}
        data={showDeleteModalData.data.name}
        confirmDelele={() => confirmDelete()}
      />
    );
  }

  return (
    <React.Fragment>
      {fetchedLabels.map(label => (
        <Label
          key={label.id}
          label={label}
          deleteLabel={() => handleShowDeleteLabel(label.id)}
        />
      ))}
      {deleteModal}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    fetchedLabels: state.LabelReducer.labels
  };
};

export default connect(
  mapStateToProps,
  { setActiveModal, deleteLabel, getLabels }
)(Labels);
