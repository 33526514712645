import {
  FETCH_SEGMENTS,
  CREATE_SEGMENT,
  DELETE_SEGMENT
} from "../../actionTypes";
import { apiFailed, resetErrorMessage } from "./NotificationActions";
import api from "../../api";

export const segmentsFetched = segments => {
  return {
    type: FETCH_SEGMENTS,
    payload: segments
  };
};
export const segmentCreated = segment => {
  return {
    type: CREATE_SEGMENT,
    payload: segment
  };
};
export const segmentDeleted = segmentId => {
  return {
    type: DELETE_SEGMENT,
    payload: segmentId
  };
};

export const fetchSegments = data => dispatch => {
  return api.segments
    .fetchSegments(data)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error("Failed!");
      }
      return res.json();
    })
    .then(resData => {
      if (resData.errors) {
        dispatch(apiFailed(resData.errors[0].message));
        setTimeout(() => {
          dispatch(resetErrorMessage());
        }, 10000);
      } else {
        dispatch(segmentsFetched(resData.data.listSegment));
        return resData;
      }
    })
    .catch(err => {
      dispatch(apiFailed(err.message));
      setTimeout(() => {
        dispatch(resetErrorMessage());
      }, 10000);
    });
};
export const createNewSegment = data => dispatch => {
  return api.segments
    .createNewSegment(data)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error("Failed!");
      }
      return res.json();
    })
    .then(resData => {
      if (resData.errors) {
        dispatch(apiFailed(resData.errors[0].message));
        setTimeout(() => {
          dispatch(resetErrorMessage());
        }, 10000);
      } else {
        dispatch(segmentCreated(resData.data.createSegment));
        return resData;
      }
    })
    .catch(err => {
      dispatch(apiFailed(err.message));
      setTimeout(() => {
        dispatch(resetErrorMessage());
      }, 10000);
    });
};
export const deleteSegment = (data, segmentId) => dispatch => {
  return api.segments
    .deleteSegment(data)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error("Failed!");
      }
      return res.json();
    })
    .then(resData => {
      if (resData.errors) {
        dispatch(apiFailed(resData.errors[0].message));
        setTimeout(() => {
          dispatch(resetErrorMessage());
        }, 10000);
      } else {
        dispatch(segmentDeleted(segmentId));
        return resData;
      }
    })
    .catch(err => {
      dispatch(apiFailed(err.message));
      setTimeout(() => {
        dispatch(resetErrorMessage());
      }, 10000);
    });
};
