import { API_FAILED, RESET_ERROR_MESSAGE } from "../../actionTypes";

const notificationState = {
  errors: []
};
export default (state = notificationState, action) => {
  switch (action.type) {
    case API_FAILED:
      state = {
        errors: [action.payload, ...state.errors]
      };
      break;
    case RESET_ERROR_MESSAGE:
      const errors = [...state.errors];
      errors.pop();
      state = {
        errors
      };
      break;
    default:
      return state;
  }
  return state;
};
