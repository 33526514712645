import { GET_LABELS, CREATE_LABEL, DELETE_LABEL } from "../../actionTypes";
import { apiFailed, resetErrorMessage } from "./NotificationActions";
import api from "../../api";

export const labelsFetched = labels => {
  return {
    type: GET_LABELS,
    payload: labels
  };
};
export const segmenetCreated = label => {
  return {
    type: CREATE_LABEL,
    payload: label
  };
};
export const segmenetDeleted = labelId => {
  return {
    type: DELETE_LABEL,
    payload: labelId
  };
};

export const getLabels = data => dispatch =>
  api.labels
    .fetchLabels(data)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error(
          "error in getting labels with status code : " + res.status
        );
      }
      return res.json();
    })
    .then(resData => {
      if (resData.errors) {
        dispatch(apiFailed(resData.errors[0].message));
        setTimeout(() => {
          dispatch(resetErrorMessage());
        }, 10000);
      } else {
        dispatch(labelsFetched(resData.data.listLabel));
      }
    })
    .catch(err => {
      dispatch(apiFailed(err.message));
      setTimeout(() => {
        dispatch(resetErrorMessage());
      }, 10000);
    });

export const createNewLabel = data => dispatch =>
  api.labels
    .createNewLabel(data)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error(
          "error in create new label with status code : " + res.status
        );
      }
      return res.json();
    })
    .then(resData => {
      if (resData.errors) {
        dispatch(apiFailed(resData.errors[0].message));
        setTimeout(() => {
          dispatch(resetErrorMessage());
        }, 10000);
      } else {
        dispatch(segmenetCreated(resData.data.createLabel));
        return resData;
      }
    })
    .catch(err => {
      dispatch(apiFailed(err.message));
      setTimeout(() => {
        dispatch(resetErrorMessage());
      }, 10000);
    });

export const associateUsersToLabel = data => dispatch =>
  api.labels
    .associateToLabel(data)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error(
          "error in associate users to label with status code : " + res.status
        );
      }
      return res.json();
    })
    .then(resData => {
      if (resData.errors) {
        dispatch(apiFailed(resData.errors[0].message));
        setTimeout(() => {
          dispatch(resetErrorMessage());
        }, 10000);
      } else {
        return resData.data.associatePeople;
      }
    })
    .catch(err => {
      dispatch(apiFailed(err.message));
      setTimeout(() => {
        dispatch(resetErrorMessage());
      }, 10000);
    });

export const deleteLabel = (data, labelId) => dispatch =>
  api.labels
    .deleteLabel(data)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error(
          "error in deleting label with status code : " + res.status
        );
      }
      return res.json();
    })
    .then(resData => {
      if (resData.errors) {
        dispatch(apiFailed(resData.errors[0].message));
        setTimeout(() => {
          dispatch(resetErrorMessage());
        }, 10000);
      } else {
        dispatch(segmenetDeleted(labelId));
        return resData;
      }
    })
    .catch(err => {
      dispatch(apiFailed(err.message));
      setTimeout(() => {
        dispatch(resetErrorMessage());
      }, 10000);
    });
