import React, { Component } from "react";
import { connect } from "react-redux";
import { disActiveModal } from "../redux/actions/ModalActions";
import ReactCSSTransitionGroup from "react-addons-css-transition-group"; // ES6

class AppModal extends Component {
  closeModal = () => {
    this.props.disActiveModal();
  };

  render() {
    if (!this.props.visable) {
      return null;
    }

    return (
      <div>
        <ReactCSSTransitionGroup
          transitionName="fade"
          transitionAppear={true}
          transitionAppearTimeout={400}
          transitionEnterTimeout={400}
          transitionLeaveTimeout={400}
          transitionEnter={true}
          transitionLeave={false}
        >
          <div className="app-modal" onClick={() => this.closeModal()} />
          <div className="app-modal-inner">
            {this.props.children}
            <button
              onClick={() => this.closeModal()}
              className="btn modal-button"
            >
              Close
            </button>
            {this.props.haveConfirmation && (
              <button
                onClick={() => this.props.confirm()}
                className="btn btn-success"
              >
                comfirm
              </button>
            )}
          </div>
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}
AppModal.defaultProps = {
  haveConfirmation: false
};

function mapStateToProps(state) {
  return {
    visable: state.ModalReducer.isModalShown,
    activeModalName: state.ModalReducer.activeModal
  };
}

export default connect(
  mapStateToProps,
  { disActiveModal }
)(AppModal);
