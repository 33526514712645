import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";

const SearchRow = props => {
  let Buttontype = (
    <div className="px-1">
      <button
        className="btn custom-btn-main"
        onClick={() => props.addNewRow(props.row.id)}
      >
        <i className="fa fa-plus" />
      </button>
    </div>
  );
  if (props.row.sub_operation && props.rowCount > 1) {
    Buttontype = (
      <div className="col-6 px-1">
        <Select
          onChange={props.onChange.bind(this, props.row.id, "sub_operation")}
          defaultValue={props.querySelection.sub_operation[0]}
          options={props.querySelection.sub_operation}
          className="search-select"
          classNamePrefix="search-select-div"
        />
      </div>
    );
  }

  return (
    <div className="row mb-4">
      <div className="col px-1">
        <Select
          onChange={props.onChange.bind(this, props.row.id, "group")}
          defaultValue={""}
          value={props.row.group}
          options={props.querySelection.group}
          placeholder="select Type"
          className="search-select"
          classNamePrefix="search-select-div"
        />
      </div>
      {props.row.group && props.row.group.value !== "segment" && (
        <div className="col px-1">
          <Select
            onChange={props.onChange.bind(this, props.row.id, "column")}
            defaultValue={props.querySelection.columns[0]}
            value={props.row.column}
            options={props.querySelection.columns}
            className="search-select"
            classNamePrefix="search-select-div"
          />
        </div>
      )}

      <div className="col px-1">
        <Select
          onChange={props.onChange.bind(this, props.row.id, "operation")}
          defaultValue={props.querySelection.operations[0]}
          value={props.row.operation}
          options={props.querySelection.operations}
          className="search-select"
          classNamePrefix="search-select-div"
        />
      </div>
      <div className="col px-1">
        <CreatableSelect
          isClearable
          isMulti
          placeholder="value"
          value={props.row.value}
          onChange={props.onChange.bind(this, props.row.id, "value")}
          options={props.querySelection.values}
          className="search-select multi-select"
          classNamePrefix="search-select-div"
        />
      </div>
      <div className="col">
        <div className="row">
          {Buttontype}
          <div className="col px-1">{props.children}</div>
        </div>
      </div>
    </div>
  );
};
export default SearchRow;
