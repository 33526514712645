import {
  USER_LOGIN,
  USER_LOGIN_FAILED,
  RESET_LOGIN_FAILED_MESSAGE,
  USER_LOGOUT
} from "../../actionTypes";

const initState = {
  isLoggedIn: false,
  user: {},
  error: null
};
export default (state = initState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      state = {
        ...state,
        isLoggedIn: true,
        user: action.payload,
        error: null
      };
      break;
    case USER_LOGOUT:
      state = {
        ...state,
        isLoggedIn: false,
        user: {}
      };
      break;
    case USER_LOGIN_FAILED:
      state = {
        ...state,
        isLoggedIn: false,
        error: action.payload
      };
      break;
    case RESET_LOGIN_FAILED_MESSAGE:
      state = {
        ...state,
        error: null
      };
      break;
    default:
      return state;
  }
  return state;
};
