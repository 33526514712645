import React from 'react';

const AlertError = ({error,onRetry}) => (
    <div className="alert alert-danger">
        {error}
        {onRetry && (
            <button className="btn btn-primary" onClick={()=>onRetry()}>
                <span className="fa fa-redo"></span>
            </button>
        )}
        
    </div>
);

export default AlertError;