import React from "react";
import PropTypes from "prop-types";
import Person from "./Person";

const People = ({ people, selectedPersons, showedPerson, selectPerson }) =>
  people.map(person => (
    <Person
      key={person.fb_id}
      person={person}
      showedPerson={personId => showedPerson(personId)}
      selectPerson={personId => selectPerson(personId)}
      wasSelected={selectedPersons.find(id => id === person._id) ? true : false}
    />
  ));

People.propTypes = {
  people: PropTypes.array.isRequired,
  selectedPersons: PropTypes.array,
  showedPerson: PropTypes.func.isRequired,
  selectPerson: PropTypes.func.isRequired
};

export default People;
