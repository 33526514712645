export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";
export const RESET_LOGIN_FAILED_MESSAGE = "RESET_LOGIN_FAILED_MESSAGE";
export const USER_LOGOUT = "USER_LOGOUT";

export const ACTIVE_MODAL = "ACTIVE_MODAL";
export const DISACTIVE_MODAL = "DISACTIVE_MODAL";

export const SET_PEOPLE = "SET_PEOPLE";
export const CHANGE_PERSON_PAUSED_VALUE = "CHANGE_PERSON_PAUSED_VALUE";
export const SET_PEOPLE_WITH_APPEND = "SET_PEOPLE_WITH_APPEND";

export const SET_BROADCAST_TO_ALL = "SET_BROADCAST_TO_ALL";
export const SET_BROADCAST_TO_PERSON = "SET_BROADCAST_TO_PERSON";
export const SET_BROADCAST_TO_SEGMENT = "SET_BROADCAST_TO_SEGMENT";

export const GET_LABELS = "GET_LABELS";
export const CREATE_LABEL = "CREATE_LABEL";
export const DELETE_LABEL = "DELETE_LABEL";

export const CREATE_CARD = "CREATE_CARD";
export const DELETE_CARD = "DELETE_CARD";
export const UPDATE_CARD = "UPDATE_CARD";
export const MOVE_CARD = "MOVE_CARD";

export const FETCH_SEGMENTS = "FETCH_SEGMENTS";
export const CREATE_SEGMENT = "CREATE_SEGMENT";
export const DELETE_SEGMENT = "DELETE_SEGMENT";

export const SUBMITED_QUERY = "SUBMITED_QUERY";
export const FETCH_QUERY_BUILDER_USER_DATA = "FETCH_QUERY_BUILDER_USER_DATA";
export const FETCH_QUERY_BUILDER_ATTRIBUTES_DATA =
  "FETCH_QUERY_BUILDER_ATTRIBUTES_DATA";
export const FETCH_QUERY_BUILDER_SEGMENTS_DATA =
  "FETCH_QUERY_BUILDER_SEGMENTS_DATA";

export const API_FAILED = "API_FAILED";
export const RESET_ERROR_MESSAGE = "RESET_ERROR_MESSAGE";


export const FETCH_PAUSED_LIST = "FETCH_PAUSED_LIST";
