import {
  SET_BROADCAST_TO_ALL,
  SET_BROADCAST_TO_PERSON,
  SET_BROADCAST_TO_SEGMENT
} from "../../actionTypes";

const initState = {
  chat: [],
  activePerson: null,
  activeLabel: null,
  toAll: true,
  toPerson: false,
  toLabel: false
};
export default (state = initState, action) => {
  switch (action.type) {
    case SET_BROADCAST_TO_PERSON:
      state = {
        ...state,
        toPerson: true,
        toAll: false,
        toLabel: false,
        activeLabel: null,
        chat: action.payload.chat,
        activePerson: action.payload.activePerson
      };
      break;
    case SET_BROADCAST_TO_SEGMENT:
      state = {
        ...state,
        toPerson: false,
        toAll: false,
        toLabel: true,
        activeLabel: action.payload.activeLabel,
        chat: action.payload.chat,
        activePerson: null
      };
      break;
    case SET_BROADCAST_TO_ALL:
      state = {
        ...state,
        toPerson: false,
        toAll: true,
        toLabel: false,
        chat: [],
        activeLabel: null,
        activePerson: null
      };
      break;
    default:
      return state;
  }
  return state;
};
