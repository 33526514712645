import React from "react";
import ChatFooterActions from "./ChatFooterActions";

const ChatArea = () => {
  return (
    <div className="mesgs">
      <ChatFooterActions />
    </div>
  );
};

export default ChatArea;
