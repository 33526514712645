import {
  CREATE_CARD,
  DELETE_CARD,
  UPDATE_CARD,
  MOVE_CARD
} from "../../actionTypes";
import update from "immutability-helper";

const modalState = {
  cards: []
};
export default (state = modalState, action) => {
  let cards = null;
  let cardIndex = null;

  switch (action.type) {
    case CREATE_CARD:
      state = {
        ...state,
        cards: [...state.cards, action.payload]
      };
      break;
    case DELETE_CARD:
      cards = [...state.cards];
      cardIndex = cards.findIndex(card => card.id === action.payload);
      if (cardIndex !== -1) {
        cards.splice(cardIndex, 1);
      }
      state = {
        ...state,
        cards
      };
      break;

    case UPDATE_CARD:
      cards = [...state.cards];
      cardIndex = cards.findIndex(card => card.id === action.payload.cardId);
      if (cardIndex !== -1) {
        cards[cardIndex].cardBody = action.payload.newCard;
      }
      state = {
        ...state,
        cards
      };
      console.log(state);
      break;

    case MOVE_CARD:
      const dragCard = cards[action.payload.dragIndex];
      update(cards, {
        $splice: [
          [action.payload.dragIndex, 1],
          [action.payload.hoverIndex, 0, dragCard]
        ]
      });

      console.log(cards);
      break;

    default:
      return state;
  }
  return state;
};
