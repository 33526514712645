import "./App.scss";
import React, { Component } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

import AuthRoute from "./components/routes/AuthRoute";
import GuestRoute from "./components/routes/GuestRoute";

import Auth from "./pages/Auth";
import PeoplePage from "./pages/PeoplePage";
import Broadcast from "./pages/Broadcast";
// import PausedList from "./pages/PausedList";

import "@fortawesome/fontawesome-free/js/all.js";

class App extends Component {
  state = {
    loading: true
  };
  componentDidMount() {
    this.setState({ loading: false });
  }
  render() {
    if (this.state.loading) {
      return <h2>loading....</h2>;
    }
    return (
      <BrowserRouter>
        <Switch>
          <GuestRoute strict exact path="/auth" component={Auth} />
          {/* <AuthRoute strict exact path="/dashboard" component={Dashboard}></AuthRoute> */}
          <AuthRoute strict exact path="/people" component={PeoplePage} />
          <AuthRoute strict exact path="/broadcast" component={Broadcast} />
          <Redirect from="/" to="/auth" />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
