import { ACTIVE_MODAL, DISACTIVE_MODAL } from "../../actionTypes";
export const activatedModal = modalName => {
  return {
    type: ACTIVE_MODAL,
    payload: modalName
  };
};

export const disactiveOpenedModal = () => {
  return {
    type: DISACTIVE_MODAL,
    payload: null
  };
};

export const setActiveModal = modalName => dispatch =>
  dispatch(activatedModal(modalName));
export const disActiveModal = () => dispatch =>
  dispatch(disactiveOpenedModal());
