import React from "react";

const Segment = ({ segment, deleteSegment }) => {
  return (
    <span className="label">
      {segment.name}
      <span className="close" onClick={() => deleteSegment()}>
        x
      </span>
    </span>
  );
};

export default Segment;
