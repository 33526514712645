import React, { Component } from "react";
import Validation from "../../../helpers/Validation";
import InlineError from "../../InlineError";
import Spinner from "../../Spinner";
import { login } from "../../../redux/actions/AuthAction";
import { connect } from "react-redux";
class LoginForm extends Component {
  state = {
    errors: {},
    isLoading: false
  };
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  constructor(props) {
    super(props);
    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
  }
  onsubmit = async e => {
    e.preventDefault();
    let formObjectValidation = {
      email: { rules: ["required", "email"], obj: this.emailRef },
      password: { rules: ["required", "password"], obj: this.passwordRef }
    };
    let errors = new Validation(formObjectValidation);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.setState({ errors: {}, isLoading: true });
      let email = this.emailRef.current.value;
      let password = this.passwordRef.current.value;

      const requestBody = {
        query: `
            query{
                login(email:"${email}",password:"${password}"){
                    userId
                    token
                    expToken
                }
            }
        `
      };
      try {
        await this.props.login(requestBody);
        this.setState({ isLoading: false });
      } catch (error) {
        console.log(error);
      }
    }
  };

  render() {
    const { errors, isLoading } = this.state;
    if (isLoading) {
      return <Spinner />;
    }

    return (
      <form className="fullw" onSubmit={this.onsubmit}>
        <h2> Get Started </h2>
        {errors.server && (
          <div className="alert alert-danger" role="alert">
            <InlineError error={errors.server} />
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <InlineError error={this.props.error} />
          </div>
        )}
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input
            type="email"
            className="form-control"
            name="email"
            ref={this.emailRef}
            placeholder="Enter email"
          />
          {errors.email && <InlineError error={errors.email[0]} />}
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Password</label>
          <input
            type="password"
            className="form-control"
            ref={this.passwordRef}
            placeholder="Password"
          />
          {errors.password && <InlineError error={errors.password[0]} />}
        </div>

        <button type="submit" className="btn btn-primary">
          Login
        </button>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.authReducer.error
  };
};
export default connect(
  mapStateToProps,
  { login }
)(LoginForm);
