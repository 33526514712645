import React from "react";
import "./Spinner.css";

const Spinner = props => {
  const colorStyle = {
    backgroundColor: props.color ? props.color : "indianred"
  };
  return (
    <div className="spinner">
      <div className="bounce1" style={colorStyle} />
      <div className="bounce2" style={colorStyle} />
      <div className="bounce3" style={colorStyle} />
    </div>
  );
};

export default Spinner;
