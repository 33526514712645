import {
  FETCH_QUERY_BUILDER_USER_DATA,
  FETCH_QUERY_BUILDER_ATTRIBUTES_DATA,
  FETCH_QUERY_BUILDER_SEGMENTS_DATA,
  SUBMITED_QUERY,
  SET_PEOPLE,
  SET_PEOPLE_WITH_APPEND
} from "../../actionTypes";
import { apiFailed, resetErrorMessage } from "./NotificationActions";
import api from "../../api";

export const queryUserDataFetched = query => {
  return {
    type: FETCH_QUERY_BUILDER_USER_DATA,
    payload: query
  };
};
export const queryAttributesDataFetched = query => {
  return {
    type: FETCH_QUERY_BUILDER_ATTRIBUTES_DATA,
    payload: query
  };
};
export const querySegmentDataFetched = query => {
  return {
    type: FETCH_QUERY_BUILDER_SEGMENTS_DATA,
    payload: query
  };
};
export const querySubmitData = people => {
  return {
    type: SET_PEOPLE,
    payload: people
  };
};
export const querySubmitDataWithAppend = people => {
  return {
    type: SET_PEOPLE_WITH_APPEND,
    payload: people
  };
};
export const queryUpdated = query => {
  return {
    type: SUBMITED_QUERY,
    payload: query
  };
};

export const getQueryUserData = data => dispatch =>
  api.queryBuilder
    .fetchQueryData(data)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error("Failed!");
      }
      return res.json();
    })
    .then(resData => {
      if (resData.errors) {
        dispatch(apiFailed(resData.errors[0].message));
        setTimeout(() => {
          dispatch(resetErrorMessage());
        }, 10000);
      } else {
        dispatch(queryUserDataFetched(resData.data.createQuery));
        return resData;
      }
    })
    .catch(err => {
      dispatch(apiFailed(err.message));
      setTimeout(() => {
        dispatch(resetErrorMessage());
      }, 10000);
    });

export const getQueryAttributesData = data => dispatch =>
  api.queryBuilder
    .fetchQueryData(data)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error("Failed!");
      }
      return res.json();
    })
    .then(resData => {
      if (resData.errors) {
        dispatch(apiFailed(resData.errors[0].message));
        setTimeout(() => {
          dispatch(resetErrorMessage());
        }, 10000);
      } else {
        dispatch(queryAttributesDataFetched(resData.data.createQuery));
        return resData;
      }
    })
    .catch(err => {
      dispatch(apiFailed(err.message));
      setTimeout(() => {
        dispatch(resetErrorMessage());
      }, 10000);
    });

export const getQuerySegmentData = data => dispatch =>
  api.queryBuilder
    .fetchQueryData(data)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error("Failed!");
      }
      return res.json();
    })
    .then(resData => {
      if (resData.errors) {
        dispatch(apiFailed(resData.errors[0].message));
        setTimeout(() => {
          dispatch(resetErrorMessage());
        }, 10000);
      } else {
        dispatch(querySegmentDataFetched(resData.data.createQuery));
        return resData;
      }
    })
    .catch(err => {
      dispatch(apiFailed(err.message));
      setTimeout(() => {
        dispatch(resetErrorMessage());
      }, 10000);
    });

export const submitQueryData = data => dispatch =>
  api.queryBuilder
    .submitQuery(data)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error("Failed submit query data !");
      }
      return res.json();
    })
    .then(resData => {
      if (resData.errors) {
        dispatch(apiFailed(resData.errors[0].message));
        setTimeout(() => {
          dispatch(resetErrorMessage());
        }, 10000);
      } else {
        dispatch(querySubmitData(resData.data.submitQueryBuilder));
        return resData;
      }
    })
    .catch(err => {
      dispatch(apiFailed(err.message));
      setTimeout(() => {
        dispatch(resetErrorMessage());
      }, 10000);
    });
export const submitQueryDataWithAppend = data => dispatch =>
  api.queryBuilder
    .submitQuery(data)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error("Failed submit Query Data With Append!");
      }
      return res.json();
    })
    .then(resData => {
      if (resData.errors) {
        dispatch(apiFailed(resData.errors[0].message));
        setTimeout(() => {
          dispatch(resetErrorMessage());
        }, 10000);
      } else {
        dispatch(querySubmitDataWithAppend(resData.data.submitQueryBuilder));
        return resData;
      }
    })
    .catch(err => {
      dispatch(apiFailed(err.message));
      setTimeout(() => {
        dispatch(resetErrorMessage());
      }, 10000);
    });

export const updateQueryBuilder = data => dispatch => {
  dispatch(queryUpdated(data));
};
