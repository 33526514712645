import { SET_PEOPLE, SET_PEOPLE_WITH_APPEND, CHANGE_PERSON_PAUSED_VALUE } from "../../actionTypes";
import { apiFailed, resetErrorMessage } from "./NotificationActions";
import api from "../../api";

export const peopleFetched = people => {
  return {
    type: SET_PEOPLE,
    payload: people
  };
};

export const changePersonPausedValue = facebookId => {
  return {
    type: CHANGE_PERSON_PAUSED_VALUE,
    payload: facebookId
  };
};

export const peopleFetchedWithAppend = people => {
  return {
    type: SET_PEOPLE_WITH_APPEND,
    payload: people
  };
};

export const getPeople = data => dispatch =>
  api.people
    .fetchPeople(data)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error("Failed to fetch people!");
      }
      return res.json();
    })
    .then(resData => {
      if (resData.errors) {
        dispatch(apiFailed(resData.errors[0].message));
        setTimeout(() => {
          dispatch(resetErrorMessage());
        }, 5000);
      } else {
        dispatch(peopleFetched(resData.data.people));
      }
    })
    .catch(err => {
      dispatch(apiFailed(err.message));
      setTimeout(() => {
        dispatch(resetErrorMessage());
      }, 5000);
    });

export const getPeopleWithAppend = data => dispatch =>
  api.people
    .fetchPeople(data)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error("Failed to fetch people with pagination!");
      }
      return res.json();
    })
    .then(resData => {
      if (resData.errors) {
        dispatch(apiFailed(resData.errors[0].message));
        setTimeout(() => {
          dispatch(resetErrorMessage());
        }, 5000);
      } else {
        dispatch(peopleFetchedWithAppend(resData.data.people));
      }
    })
    .catch(err => {
      dispatch(apiFailed(err.message));
      setTimeout(() => {
        dispatch(resetErrorMessage());
      }, 5000);
    });


    export const changePause = (facebookId,currentPausedValue) => dispatch =>
      api.people
        .changePause(facebookId,currentPausedValue)
        .then(res => {
          if (res.status !== 200 && res.status !== 201) {
            throw new Error("Failed to change pause!");
          }
          return res.json();
        })
        .then(resData => {
          if (resData.errors) {
            dispatch(apiFailed(resData.errors[0].message));
            setTimeout(() => {
              dispatch(resetErrorMessage());
            }, 5000);
          } else {
            dispatch(changePersonPausedValue(facebookId));
          }
        })
        .catch(err => {
          dispatch(apiFailed(err.message));
          setTimeout(() => {
            dispatch(resetErrorMessage());
          }, 5000);
        });
