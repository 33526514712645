import { ACTIVE_MODAL, DISACTIVE_MODAL } from "../../actionTypes";

const modalState = {
  activeModal: null,
  isModalShown: false
};
export default (state = modalState, action) => {
  switch (action.type) {
    case ACTIVE_MODAL:
      state = {
        ...state,
        activeModal: action.payload,
        isModalShown: true
      };
      break;
    case DISACTIVE_MODAL:
      state = {
        ...state,
        activeModal: null,
        isModalShown: false
      };
      break;
    default:
      return state;
  }
  return state;
};
