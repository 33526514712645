import React from "react";

const Label = ({ label, deleteLabel }) => {
  return (
    <span className="label">
      {label.name}
      <span className="close" onClick={() => deleteLabel(label.id)}>
        x
      </span>
    </span>
  );
};

export default Label;
