import {
  USER_LOGIN,
  USER_LOGIN_FAILED,
  RESET_LOGIN_FAILED_MESSAGE,
  USER_LOGOUT
} from "../../actionTypes";
import api from "../../api";

export const userLoggedIn = user => {
  return {
    type: USER_LOGIN,
    payload: user
  };
};
export const userLoggedInFailed = errorMessage => {
  return {
    type: USER_LOGIN_FAILED,
    payload: errorMessage
  };
};
export const resetErrorMessage = () => {
  return {
    type: RESET_LOGIN_FAILED_MESSAGE
  };
};
export const userLoggedOut = () => {
  return {
    type: USER_LOGOUT,
    payload: {}
  };
};

export const login = data => dispatch =>
  api.user
    .login(data)
    .then(res => {
      return res.json();
    })
    .then(resData => {
      if (resData.errors) {
        dispatch(userLoggedInFailed(resData.errors[0].message));
        setTimeout(() => {
          dispatch(resetErrorMessage());
        }, 5000);
      } else {
        localStorage.setItem("userData", JSON.stringify(resData.data.login));
        dispatch(userLoggedIn(resData.data.login));
      }
      return resData;
    });

export const logout = () => dispatch => {
  localStorage.removeItem("userData");
  dispatch(userLoggedOut());
};
