import _ from "lodash";
export const _getObjectKeyValues = object => {
  return Object.keys(object).reduce(function(result, key) {
    if (object[key] != null) {
      if (object[key] instanceof Array) {
        result[key] = object[key].map(value => value.value);
      } else if (object[key] instanceof Object) {
        result[key] = object[key].value;
      }
    } else {
      result[key] = null;
    }
    return result;
  }, {});
};

export const _arrayValues = (value, plucked_key) => {
  return _.map(value, plucked_key);
};
