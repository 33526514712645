import React, { useState } from "react";
import Labels from "./labels/Labels";
import { connect } from "react-redux";
import {
  createNewLabel,
  associateUsersToLabel
} from "../../../redux/actions/LabelActions";

const LabelsList = ({
  createNewLabel,
  selectedPersons,
  associateUsersToLabel
}) => {
  const [label, changeLabel] = useState(null);
  const [editableLabel, changeEditableLabel] = useState(null);

  const createLabelRequestBody = label => {
    return {
      query: `
            mutation{
                createLabel(
                  name:"${label.name}",
                  ){
                    label_id
                  name
                }
               }
            `
    };
  };
  const associateLabelRequestBody = label_id => {
    return {
      query: `
      mutation{
        associatePeople(associates:${JSON.stringify(
          selectedPersons
        )},labelId:"${label_id}"){
            label_id
            people_count
          }
    }

            `
    };
  };

  const createLabel = () => {
    let newLabel = {
      _id: Date.now(),
      name: `New label`
    };
    changeLabel(newLabel);
    changeEditableLabel(newLabel._id);
  };

  const handleLabelNameChange = event => {
    changeLabel({
      name: event.target.value
    });
  };

  const saveLabel = async () => {
    try {
      const createLabelRequest = createLabelRequestBody(label);
      const response = await createNewLabel(createLabelRequest);
      const savedLabel = response.data.createLabel;
      labelAssociateUsers(savedLabel.label_id);
      changeEditableLabel(null);
      changeLabel(null);
    } catch (error) {
      console.log(error);
    }
  };

  const labelAssociateUsers = async label_id => {
    try {
      await associateUsersToLabel(associateLabelRequestBody(label_id));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <Labels />

        {label && (
          <React.Fragment>
            <input
              type="text"
              autoFocus
              value={label.name}
              onChange={event => handleLabelNameChange(event)}
            />
            <button
              className="btn btn-primary"
              title="select users first"
              onClick={() => saveLabel()}
            >
              save segment
            </button>
          </React.Fragment>
        )}
        <button
          className="btn add-btn custom-btn-main"
          title="select users first"
          disabled={editableLabel || selectedPersons.length < 1}
          onClick={() => createLabel()}
        >
          +
        </button>
      </div>
    </div>
  );
};
export default connect(
  null,
  { createNewLabel, associateUsersToLabel }
)(LabelsList);
