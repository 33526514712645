import { FETCH_PAUSED_LIST } from "../../actionTypes";

const initState = {
  pausedList: []
};

export default (state = initState, action) => {
  switch (action.type) {
    case FETCH_PAUSED_LIST:
      state = {
        pausedList: action.payload
      };
      break;
    default:
      return state;
  }
  return state;
};
