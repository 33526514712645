import React from "react";
import AppModal from "../AppModal";

const DeleteModal = ({ type, data, confirmDelele }) => {
  return (
    <div>
      <AppModal
        name="deleteModal"
        confirm={() => confirmDelele()}
        haveConfirmation="true"
      >
        <p>
          Are you sure you want to delete <b>{type}</b> name <b>{data}</b>.
        </p>
      </AppModal>
    </div>
  );
};

export default DeleteModal;
