import { API_FAILED, RESET_ERROR_MESSAGE } from "../../actionTypes";

export const apiFailed = errorMessage => {
  return {
    type: API_FAILED,
    payload: errorMessage
  };
};
export const resetErrorMessage = () => {
  return {
    type: RESET_ERROR_MESSAGE
  };
};
