import React from "react";
import { logout } from "../redux/actions/AuthAction";
import { connect } from "react-redux";
const MainHeader = ({ logout }) => {
  return (
    <React.Fragment>
      <header>
        <nav className="navbar navbar-expand-lg bg-white">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item user-search">
                <a className="nav-link" href="#">
                  <i className="fas fa-search" />
                </a>
              </li>

              <li className="nav-item user-not">
                <a className="nav-link" href="#">
                  <i className="far fa-bell" />
                </a>
              </li>

              <li className="nav-item user-cogs">
                <a className="nav-link" href="#">
                  <i className="fas fa-cog" />
                </a>
              </li>

              <li className="nav-item user-account">
                <a className="nav-link" href="#" onClick={() => logout()}>
                  <span>
                    {" "}
                    Hi, <b> Ahmed </b>{" "}
                  </span>
                  <span className="user-img">A</span>
                  {/*  onClick={()=>logout()} <img className="user-avatar" src="https://scontent-hbe1-1.xx.fbcdn.net/v/t1.0-1/p24x24/43750756_2152360561461651_2548342613707063296_n.jpg?_nc_cat=101&_nc_ht=scontent-hbe1-1.xx&oh=f7183a77454c4da3528d305e9b1bc2db&oe=5D111A20" alt="user-pp"/> */}
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </React.Fragment>
  );
};

export default connect(
  null,
  { logout }
)(MainHeader);
