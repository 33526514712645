import { SET_PEOPLE, SET_PEOPLE_WITH_APPEND,CHANGE_PERSON_PAUSED_VALUE } from "../../actionTypes";

const initState = {
  people: {}
};
export default (state = initState, action) => {
  switch (action.type) {
    case SET_PEOPLE:
      state = {
        ...state,
        people: action.payload
      };
      break;
    case SET_PEOPLE_WITH_APPEND:
      if (Object.keys(state.people).length > 0) {
        let { people, ...peopleMeta } = action.payload;
        let peopleArray = people;
        state = {
          people: {
            people: [...state.people.people, ...peopleArray],
            ...peopleMeta
          }
        };
      } else {
        state = {
          ...state,
          people: action.payload
        };
      }
      break;
      case CHANGE_PERSON_PAUSED_VALUE:
        const peopleArray = [...state.people.people];
        const personIndex = peopleArray.findIndex(person=>person.fb_id === action.payload );
        if (personIndex !== -1) {
          peopleArray[personIndex].paused = !peopleArray[personIndex].paused;

          state = {
            people: {
              people: [...peopleArray],
            }
          };
        }

        break;
    default:
      return state;
  }
  return state;
};
