import React, { Component } from "react";
import PeopleTable from "../components/people/PeopleTable";
import Spinner from "../components/Spinner";
import PersonModal from "../components/modals/PersonModal";
import SearchBuilder from "../components/search/SearchBuilder";
import AlertError from "../components/AlertError";
import SegmentsList from "../components/people/segmentsList/SegmentsList";
import LabelsList from "../components/people/labelsList/LabelsList";
import { getPeopleWithAppend } from "../redux/actions/PeopleActions";
import { setActiveModal } from "../redux/actions/ModalActions";
import { connect } from "react-redux";

class PeoplePage extends Component {
  state = {
    selectedPersons: [],
    selectAll: false,
    isLoading: true,
    showedPerson: null,
    current: 1
  };

  listenScrollEvent = event => {
    if (
      event.target.scrollHeight - event.target.scrollTop ===
        event.target.clientHeight &&
      this.props.meta.pageNumbers > this.state.current
    ) {
      this.setState(prevState => {
        return {
          current: prevState.current + 1
        };
      });
    }
  };

  async componentDidMount() {
    try {
      this.setState(prevState => {
        return {
          isLoading: false
        };
      });
    } catch (error) {}
  }

  handleShowedPerson = id => {
    const person = this.props.people.find(person => person._id === id);
    this.setState({
      ...this.state,
      showedPerson: person,
      showDeleteModalData: null
    });
    this.props.setActiveModal("personModal");
  };

  handleSelectedPerson = id => {
    var people = [...this.state.selectedPersons]; // make a separate copy of the people
    var index = people.indexOf(id);
    if (index !== -1) {
      people.splice(index, 1);
      this.setState({
        ...this.state,
        selectedPersons: people
      });
    } else {
      this.setState({
        ...this.state,
        selectedPersons: [...this.state.selectedPersons, id]
      });
    }
  };
  handleToggleSelectPeople = () => {
    if (this.state.selectAll) {
      this.setState({
        selectAll: false,
        selectedPersons: []
      });
    } else {
      this.setState({
        selectAll: true,
        selectedPersons: this.props.people.map(person => person._id)
      });
    }
  };

  resetCurrentPage = () => {
    this.setState(prevState => {
      return {
        current: 1
      };
    });
  };

  render() {
    const { isLoading, showedPerson, selectedPersons } = this.state;

    if (isLoading) {
      return <Spinner />;
    }

    let personModal = null;
    if (showedPerson) {
      personModal = <PersonModal user={showedPerson} />;
    }

    return (
      <React.Fragment>
        {this.props.labelError && (
          <AlertError
            error={this.props.labelError}
            onRetry={this.fetchLabels}
          />
        )}
        <section className="wrap position-relative">
          <h6 className="mb-4">Facebook Labels</h6>
          <LabelsList selectedPersons={selectedPersons} />
          <hr />
          <h6 className="mb-4">System Segment</h6>
          <SegmentsList />
          <hr />
          <h6>Filter</h6>
          <div className="container">
            <SearchBuilder
              page={this.state.current}
              resetPage={this.resetCurrentPage}
              hasNextPage={this.props.meta.hasNextPage}
            />
          </div>
        </section>

        <section className="wrap">
          {personModal}

          <PeopleTable
            listenScrollEvent={this.listenScrollEvent}
            people={this.props.people}
            meta={this.props.meta}
            selectedPersons={this.state.selectedPersons}
            showedPerson={this.handleShowedPerson}
            handleSelectedPerson={this.handleSelectedPerson}
            handleToggleSelectPeople={this.handleToggleSelectPeople}
          />
        </section>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    people: state.PeopleReducer.people.people,
    meta: {
      limit: state.PeopleReducer.people.limit,
      count: state.PeopleReducer.people.count,
      hasNextPage: state.PeopleReducer.people.hasNextPage,
      pageNumbers: state.PeopleReducer.people.pageNumbers
    }
  };
}

export default connect(
  mapStateToProps,
  {
    getPeopleWithAppend,
    setActiveModal
  }
)(PeoplePage);
