import React, { PureComponent } from "react";
import ChatArea from "../components/boradcast/chatArea/ChatArea";
import Spinner from "../components/Spinner";

class Broadcast extends PureComponent {
  state = {
    isLoading: true
  };
  componentDidMount() {
    this.setState(prevState => {
      return {
        isLoading: false
      };
    });
  }

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="messaging">
            <div className="inbox_msg">
              <ChatArea />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Broadcast;
